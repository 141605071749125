import {useNavigate, useParams} from "react-router";
import Navbar from "../components/Navbar/Navbar";
import {useTranslation} from "react-i18next";
import CartSummary from "../components/Cart/CartSummary/CartSummary";
import {useCallback, useEffect, useState} from "react";
import i18n from "../i18n";
import axios, {AxiosError, AxiosResponse} from "axios";
import Payment from "../models/payment";
import Cart from "../models/cart";
import {mustache} from "../Helpers/functions";
import PaymentItemsList from "../components/PaymentItems/PaymentItemsList";
import PaymentFooter from "../components/PaymentItems/PaymentFooter";
import {Col, Container, Row} from "react-bootstrap";
import style from "../components/PaymentItems/Payment.module.css";
import {emptyCart, emptyPayment} from "../Constants/defaultData";
import useHttp from "../Hooks/use-http/use-http";
import {DynamicObject} from "../models/General";
import {configGetCartApi, configGetPaymentApi} from "../Api/api.config";


const PaymentView = () => {
    const {t} = useTranslation()

    const [loading, setLoading] = useState<boolean>(true)
    const [payment, setPayment] = useState<Payment>(emptyPayment)
    const [cart, setCart] = useState<Cart>(emptyCart)

    let {paymentSecret} = useParams()
    let navigate = useNavigate()

    /*-----------------------------
    *           REQUEST
    * ----------------------------*/
    const getPaymentHttp = useHttp<DynamicObject>(configGetPaymentApi)
    const requestGetPayment = getPaymentHttp.request

    getPaymentHttp.request = useCallback((args) => {

        const success = (response: AxiosResponse) => {
            setPayment(response.data.payment)
            setCart(response.data.cart)
            console.log(response.data.payment, 'here')
            setLoading(false)
        }
        const error = (response: AxiosError) => {
            navigate(process.env.REACT_APP_404_REDIRECT)
        }

        requestGetPayment({
            config: {
                paymentSecret: paymentSecret
            },
            callbacks: {
                success,
                error,
            }
        })
    }, [paymentSecret])


    useEffect(() => {
        getPaymentHttp.request()
    }, [paymentSecret])

    return (
        <div>
            <PaymentItemsList loading={loading} cartSummary={cart.summary} cartId={cart.cartId}
                              items={payment.available_methods}/>
            <PaymentFooter loading={loading} lines={payment.footer}/>
        </div>
    )
}

export default PaymentView