import style from './Navbar.module.css'
import {Col, Row} from "react-bootstrap";
import {IoChevronBack, IoChevronForward} from "react-icons/io5";
import {IoIosClose} from "react-icons/io";
import {useTranslation} from "react-i18next";
import {Skeleton} from "@mui/material";
import {useNavigate} from "react-router";
import i18n from "i18next";

export interface Props {
    title: string;
    subTitle: string;
    loading: boolean;
    cancel?: boolean;
    referral_url?:string
}

const Navbar = (props: Props) => {
    let navigate = useNavigate();
    const {t} = useTranslation()

    const onClickBackHandler=()=>{
        if (props.referral_url){
            window.location.href = props.referral_url
            return
        }
        navigate(-1)
    }


    return (
        <div dir={i18n.language=='en'?'ltr':'rtl'} className={`${style.container}`}>
            <header className={style.navbar_container}>
                {props.cancel ? <IoIosClose onClick={onClickBackHandler}></IoIosClose> :
                    i18n.language=='en'?<IoChevronBack onClick={() => onClickBackHandler()} />:
                    <IoChevronForward onClick={() => onClickBackHandler()}></IoChevronForward>
                }
                <div className={`${style.navbar_help} ${style.cursor}`} onClick={(event) => {
                    window.location.href=`https://jo.opensooq.com/${i18n.language}/support`

                }}>
                    {t('navbar.help')}
                </div>
            </header>
            <Row>
                <Col>
                    {
                        <span className={'basic_height'}><b className={'md_text'}>{props.title}</b>
                        <p className={'grey_text'}>{props.loading ?
                            <Skeleton width={"130px"}/> : props.subTitle}</p></span>}
                </Col>
            </Row>
        </div>
    )
}

export default Navbar