import React, {useCallback, useEffect, useState} from "react";
import {DynamicObject} from "../models/General";
import Navbar from "../components/Navbar/Navbar";
import Status from "../components/Status/Status";
import useHttp from "../Hooks/use-http/use-http";
import {useNavigate, useParams} from "react-router";
import {AxiosError, AxiosResponse} from "axios";
import {configGetPaymentFailedStatusApi, configGetPaymentStatusApi} from "../Api/api.config";
import {getLang, getUrlBasedOnCountry, mustache} from "../Helpers/functions";


const PaymentStatus = () => {

    let {paymentMethod,status, paymentSecret, cartId} = useParams();
    let navigate = useNavigate();



    /*-----------------------------
     *           STATES
     * ----------------------------*/
    const [data, setData] = useState<DynamicObject | undefined>()



    /*-----------------------------
     *           REQUEST
     * ----------------------------*/
    const PostPaymentFailedStatusHttp = useHttp<DynamicObject>(configGetPaymentFailedStatusApi)
    const getPaymentStatusHttp = useHttp<DynamicObject>(configGetPaymentStatusApi)
    const requestGetPaymentStatus = getPaymentStatusHttp.request

    getPaymentStatusHttp.request = useCallback((args) => {

        const success = (response: AxiosResponse) => {
            let data = response.data
            data['status'] = 200
            setData(data)
        }
        const error = (error: AxiosError) => {
            let data = {}
            data['gatewayStatusMessage'] = error.response.data[0].message ?? ''
            data['status'] = 500
            setData(data)
        }

        requestGetPaymentStatus({
            config: {
                paymentSecret: paymentSecret
            },
            callbacks: {
                success,
                error,
            }
        })
    }, [paymentSecret])


    useEffect(() => {
        getPaymentStatusHttp.request()
    }, [paymentSecret])


    /*-----------------------------
     *   REQUEST API ON FAILED BUTTON
     *            HANDLER
     * ----------------------------*/
    const onClickFailedHandler = () => {
        const requestPostPaymentFailedStatus = PostPaymentFailedStatusHttp.request

        const success = (response: AxiosResponse) => {
            window.location.href=response.data.redirect
        }
        const error = (error: AxiosError) => {
            navigate(process.env.REACT_APP_404_REDIRECT);
        }

        requestPostPaymentFailedStatus({
            config: {
                paymentSecret: paymentSecret
            },
            callbacks: {
                success,
                error,
            }
        })


    }

    /*-----------------------------
     *   REQUEST API ON SUCCESS BUTTON
     *            HANDLER
     * ----------------------------*/
    const onClickSuccessHandler = () => {
        // window.location.href = getUrlBasedOnCountry(localStorage.getItem('countryCode') ?? 'jo', getLang());
        window.location.href = getUrlBasedOnCountry(localStorage.getItem('countryCode') ?? 'jo', getLang());
    }

    return (
        <div>
            {
                data &&  <Navbar title={''} subTitle={''} referral_url={data.referral_url} cancel={true} loading={false}/>
            }
            {
                data && (data.order_status === 'success' ?
                    <Status success={data.order_status} data={data} onClickHandler={onClickSuccessHandler}/> :
                    <Status success={data.order_status} data={data} onClickHandler={onClickFailedHandler}/>)
            }

        </div>
    )
}


export default PaymentStatus