import style from './Payment.module.css'
import {useTranslation} from "react-i18next";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import i18n from "../../i18n";

export interface Props {
    lines: string[]
    loading:boolean
}

const PaymentFooter = (props: Props) => {
    const {t} = useTranslation()
    return (

        props.loading? <div style={{padding:"15px"}}> <Skeleton width={"100%"} height={"1em"} count={1} /> </div>:
        <>
            <div className={`${style.footer_container}`}>
                <span className={`${style.liner_font_other}`}>{t('payment.footer.help')} <a className={`${style.liner_link}`} href={'#'}>{t('payment.footer.helpLink')}</a></span>

            </div>
            {props.lines &&
                <div className={`${style.lines_container}`}>
                    <ul className={`${style.padding_0}`} dir={i18n.language=='en'?'ltr':'rtl'} >
                        {
                            props?.lines?.map((line) => {
                                return <li className={`${style.liner_font}`}>{line}</li>
                            })
                        }
                    </ul>
                </div>

            }

        </>


    )

}

export default PaymentFooter