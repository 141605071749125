import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import CartView from "./Pages/CartView";
import i18n from "./i18n";
import PaymentView from "./Pages/PaymentView";
import PaymentMethod from "./Pages/PaymentMethod";
import PaymentStatus from "./Pages/PaymentStatus";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Error404} from "./components/Errors/Error404";
import InternalFrame from "./Pages/InternalFrame";
import {getLang} from "./Helpers/functions";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
function App() {
    const [isReady, setIsReady] = useState<boolean>(false)

    useEffect(() => {
        i18n.changeLanguage(getLang())
        setIsReady(true)
    }, [])

    Sentry.init({
        dsn: process.env.REACT_APP_DSN,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });

    return (
        isReady &&
        <BrowserRouter>
            <Routes>
                <Route path={`${process.env.REACT_APP_PAYMENT_DIR}/select-payment/:memberId/:cartId/:identification`} element={<CartView/>}></Route>
                <Route path={`${process.env.REACT_APP_PAYMENT_DIR}/payment/:paymentSecret`} element={<PaymentView/>}></Route>
                <Route path={`${process.env.REACT_APP_PAYMENT_DIR}/payment/:method/:paymentSecret/:webView?`} element={<PaymentMethod/>}></Route>
                <Route path={`${process.env.REACT_APP_PAYMENT_DIR}/payment/:paymentMethod/:status/:paymentSecret/:cartId`} element={<PaymentStatus/>}></Route>
                <Route path={`${process.env.REACT_APP_PAYMENT_DIR}/internalFrame`} element={<InternalFrame/>}></Route>
                <Route path={'*'} element={<Error404/>}></Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
