import {AxiosResponse} from "axios";
import PaymentApi from "../Base/payment-api";
import {DynamicObject} from "../../models/General";

export default (config?: DynamicObject): Promise<AxiosResponse> => {

    /*Get Request Instance From Payment Api*/
    const request = PaymentApi.getInstance().request

    return request.post(`${process.env.REACT_APP_PAYMENT_API_URL}/${process.env.REACT_APP_VERSION}/checkout/delete-coupon/${config?.memberId}/${config?.cartId}/${config?.identification}`, config.data)
}