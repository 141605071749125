import {PaymentItem as PaymentItemModel} from "../../models";
import style from "./Payment.module.css";
import {Col, Row} from "react-bootstrap";
import {IoChevronBack, IoChevronForward} from "react-icons/io5";
import PaymentItemGroup from "../../models/payment-item-group";
import axios, {AxiosError, AxiosResponse} from "axios";
import i18n from "../../i18n";
import {useNavigate, useParams} from "react-router";
import Summary from "../../models/summary";
import {useCallback, useEffect, useState} from "react";
import UseCreditPopup from "../Credit/UseCreditPopup/UseCreditPopup";
import {useTranslation} from "react-i18next";
import {FRAME, FRAME_WITHOUT_HEADERS, INTERNAL_FRAME, REDIRECT} from "../../Constants/viewType";
import ZeroCreditPopup from "../Credit/ZeroCreditPopup/ZeroCreditPopup";
import useHttp from "../../Hooks/use-http/use-http";
import {DynamicObject} from "../../models/General";
import {configGetPaymentApi, configGetPaymentMethodApi, configPushPaymentApi} from "../../Api/api.config";

export interface Props {
    item: PaymentItemModel | PaymentItemGroup
    cartId: number;
    clearFix: boolean;
    cartSummary: Summary,
    payment_secret?:string
}

const PaymentItem = (props: Props) => {

    const {t} = useTranslation()
    const navigate = useNavigate();
    const [show, setShow] = useState<boolean>(false)
    const getIcon = (key: string) => {
        return require(`../../Assets/Images/Payments/${key}.png`)
    }

    const getIconList = (methods: PaymentItemModel[]) => {
        return methods.map((method) => {
            return <img src={getIcon(method.key)} className={style.item_sub_img} alt={''}/>
        })
    }

    const toggleShow = () => {
        setShow(!show)
    }

    const pushPayment = () => {

        const url = props.item.url
        const data = {
            ...props.item.postData,
            unified: true
        }


        /*-----------------------------
         *           REQUEST
         * ----------------------------*/
        // const postPushPaymentHttp = useHttp<DynamicObject>(configPushPaymentApi)
        // const requestPostPushPayment = postPushPaymentHttp.request
        //
        // postPushPaymentHttp.request = useCallback((args) => {
        //
        //     const success = (response: AxiosResponse) => {
        //
        //     }
        //     const error = (response: AxiosError) => {
        //     }
        //
        //     requestPostPushPayment({
        //         config: {
        //             url:url,
        //             data:data
        //         },
        //         callbacks: {
        //             success,
        //             error,
        //         }
        //     })
        // }, [url,data])




        axios.post(
            url,
            data,
            {
                headers: {
                    country: 'jo',
                    source: 'desktop',
                    "enforce-language": i18n.language
                }
            }
        ).then(response => {

            // if (props.item['key'] == 'MediaWorld') {
            //
            //     const url = 'f777631c2d77787'
            //     navigate(`/payment/internalFrame/${url}`)
            //     return
            // }

            // return


            if (props.item.viewType['type'] == FRAME || props.item.viewType['type'] ==  FRAME_WITHOUT_HEADERS) {

                const webView = ''
                navigate(`${process.env.REACT_APP_PAYMENT_DIR}/payment/${props.item.method_name}/${props.payment_secret}${webView}`, {
                    state: {
                        methodData: response.data,
                        label: props.item.label,
                        cartId: props.cartId,
                        cartSummary: props.cartSummary,
                        withoutHeaders: props.item.viewType['type'] == FRAME_WITHOUT_HEADERS
                    },
                })
            } else if (props.item.viewType['type'] == REDIRECT) {

                window.location.href = response.data.redirectTo
            } else if (props.item.viewType['type'] == INTERNAL_FRAME) {
                navigate(`${process.env.REACT_APP_PAYMENT_DIR}/internalFrame`,{
                    state: {
                        data: response.data,
                        cartId:props.cartId

                    }
                })
            }
        })
    }

    return (
        <div  dir={i18n.language=='en'?'ltr':'rtl'}>
            <div className={style.item_container} onClick={() => {
                props.item.method_name === "Credit" ? toggleShow() : pushPayment()
            }}>
                <div className={style.item_wrapper} >
                    <Row className={style.item_row}>
                        <Col className={`${style.item_col}`}>
                            <div className={`${i18n.language=='en'?'float-start':'float-end'} `}>
                            <span className={`${style.pad_text} ${style.size_text}`}>
                                {
                                    props.item.method_name === "Credit" ? t('wallet.payment.credit') : props.item.label
                                }
                            </span>
                            </div>
                            <div className={`${i18n.language=='en'?'float-end':'float-start'}`}>
                                <div className={style.holder_img}>
                                    <div className={style.item_img}>
                                        {
                                            props.item.method_name === "Credit" ?
                                                <span className={`${style.pad_text_right} ${style.size_text}`}
                                                      dangerouslySetInnerHTML={{
                                                          __html: props.item.method_name === "Credit" ? `<span class="${style.font_size_sm}">${props.item.wallet.local}</span> <span class="${style.font_size_sm}">${props.item.wallet.localCurrency}</span>` : ''
                                                      }}></span> : <div></div>
                                        }
                                        {
                                            !props.item.isGroup && "key" in props.item &&
                                            <img src={getIcon(props.item.key)} className={style.item_sub_img} alt={''}/>
                                        }

                                        {
                                            props.item.isGroup && "methods" in props.item && getIconList(props.item.methods)
                                        }
                                        {i18n.language=='en'?<IoChevronForward
                                            className={`${style.item_sub_img} ${style.blue_color}`}></IoChevronForward>:<IoChevronBack
                                            className={`${style.item_sub_img} ${style.blue_color}`}></IoChevronBack>
                                        }



                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                {!props.clearFix ?'' : ''}
            </div>
            {

                props.item.method_name === 'Credit' ?
           props.item.showCreditPopup && show ? <UseCreditPopup payment_secret={props.payment_secret} invokeToggle={() => {

                            toggleShow()
                        }} invokePopup={show} wallet={props.item.wallet} summary={props.cartSummary}/> :
                        <ZeroCreditPopup invokeToggle={() => {
                            toggleShow()
                        }} invokePopup={show} title={t('zeroBalance')} />
                 : <div></div>
            }
        </div>

    )
}

export default PaymentItem