import Load from "../../Load/Load";
import {Button} from "@mui/material";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import style from './UseCreditPopup.module.css'
import {IoIosCloseCircle} from "react-icons/io";

import axios from "axios";
import i18n from "../../../i18n";

import {UseCreditPopupProps} from "../Interface";
import {useNavigate, useParams} from "react-router";
import {Col, Container, Row} from "react-bootstrap";
import {mustache} from "../../../Helpers/functions";
import {DynamicObject} from "../../../models/General";
import {AxiosError, AxiosResponse} from "axios/index";
import useHttp from "../../../Hooks/use-http/use-http";
import {configUseCreditApi} from "../../../Api/api.config";


const UseCreditPopup = (props: UseCreditPopupProps) => {
    const {paymentSecret} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [disabled, setDisabled] = useState<boolean>(false)
    const [load, setLoad] = useState<boolean>(false)
    const postUseCreditHttp = useHttp<DynamicObject>(configUseCreditApi)


    const closePopup = () => {

        const elem = document.getElementById('wrapper');
        const bg = document.getElementById('background-popup');
        bg.style.display = "none";
        elem.style.transition = "all 0.3s ease-out-in";
        elem.style.bottom = `-${(elem.offsetHeight).toString()}px`;

    }


    const showPopup = () => {
        const elem = document.getElementById('wrapper');
        const bg = document.getElementById('background-popup');
        bg.style.display = "block";
        elem.style.transition = "all 0.3s ease-in-out";
        elem.style.bottom = "0px";
    }

    const handleClickOutside = () => {
        props.invokeToggle()
    }

    useEffect(() => {
        const bg = document.getElementById('background-popup');
        if (props.invokePopup) {
            bg.addEventListener("mousedown", handleClickOutside)
            showPopup()
        } else {
            bg.removeEventListener("mousedown", handleClickOutside)
            closePopup()
        }
    }, [props.invokePopup])



    /*-----------------------------
     *      USE CREDIT REQUEST
     * ----------------------------*/
    const useCredit = () => {
        setDisabled(true)
        setLoad(true)
        const requestPostUseCredit = postUseCreditHttp.request

        const success = (response: AxiosResponse) => {
            navigate(`${process.env.REACT_APP_PAYMENT_DIR}/payment/credit/success/${props.payment_secret}/${props.summary.cartId}`)

        }
        const error = (response: AxiosError) => {
            navigate(`${process.env.REACT_APP_PAYMENT_DIR}/payment/credit/failed/${props.payment_secret}/${props.summary.cartId}`)
        }

        requestPostUseCredit({
            config: {
                data: {
                    Credit: {
                        paymentSecret: props.payment_secret
                    }
                }

            },
            callbacks: {
                success,
                error,
            }

        })
    }

    return (
        <Container className={`${style.wrapper}`} id={'wrapper'}>
            <Row className={`justify-content-center`}>
                <Col lg={8} md={10} sm={12}>
                    <div className={`${style.color_white}`}>
                        <div className={`${style.max_pad}`}>
                            <Row>
                                <Col>
                                    <div className={`${style.title_text}`}>{t('wallet.pay')}</div>
                                </Col>
                                <Col className={`${style.flex_end}`} sm={4} lg={4} md={4} xs={4}>
                                    <IoIosCloseCircle onClick={props.invokeToggle}
                                                      className={`float-start ${style.close_btn}`}></IoIosCloseCircle>
                                </Col>
                            </Row>
                            <Row className={`mt-3 ${style.text_sizing}`}>
                                <Col>
                                    <Row className={`mb-3`}>
                                        <Col sm={4} lg={4} md={4} xs={4}>
                                            <div className={`float-end`}>
                                                {t('wallet.credit')}
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className={`float-start`}>
                                                {mustache(t('wallet.current.credit'), {
                                                    amount: props.wallet.local,
                                                    currency: props.wallet.localCurrency,
                                                    points: props.wallet.localValue.toLocaleString()
                                                })}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className={`mb-3`}>
                                        <Col sm={4} lg={4} md={4} xs={4}>
                                            <b className={`float-end`}>{t('total.total')}</b>
                                        </Col>
                                        <Col>
                                            <div className={`float-start`}>
                                                <b>{props.summary.total} {props.summary.currency}</b>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className={`mb-3 justify-content-center`}>
                                        <Col className={`d-flex justify-content-center`}>
                                            <Button size={"large"} onClick={useCredit} className={`${style.button}`}
                                                    variant={"contained"} fullWidth={true} disabled={disabled}>
                                                {load ? <div>
                                                    <Load color={'white'}/>
                                                </div> : t('purchase.now')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default UseCreditPopup