import {useLocation, useNavigate, useParams} from "react-router";
import Navbar from "../components/Navbar/Navbar";
import IframeContainer from 'iframe-script-container';
import {mustache} from "../Helpers/functions";
import {useTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import CartSummary from "../components/Cart/CartSummary/CartSummary";
import {useCallback, useEffect, useState} from "react";
import  {AxiosError, AxiosResponse} from "axios/index";
import axios from "axios";

import i18n from "../i18n";
import {FRAME, FRAME_WITHOUT_HEADERS, INTERNAL_FRAME, REDIRECT} from "../Constants/viewType";
import useHttp from "../Hooks/use-http/use-http";
import {DynamicObject} from "../models/General";
import {
    configGetPaymentApi,
    configGetPaymentFailedStatusApi,
    configGetPaymentMethodApi,
    configGetPaymentStatusApi
} from "../Api/api.config";
import Cart from "../models/cart";

const PaymentMethod = () => {


    const {method,paymentSecret} = useParams()
    const navigate = useNavigate()
    const location = useLocation();
    const [data,setData] = useState<DynamicObject>()
    const [cart,setCart] = useState<Cart>()
    const [fetchHtml,setFetchHtml] = useState<DynamicObject>()


    const getHtml = useCallback(()=>{
        console.log(data)

    }, [data])

    /*-----------------------------
    *           REQUEST
    * ----------------------------*/
    const GetPaymentMethodHttp = useHttp<DynamicObject>(configGetPaymentMethodApi)
    const requestGetPaymentMethod = GetPaymentMethodHttp.request
    GetPaymentMethodHttp.request =  (args) => {
        const success = (response: AxiosResponse) => {
            setData(response.data)
        }
        const error = (response: AxiosError) => {

            navigate('/page-not-found')
            //todo make validation
        }

        requestGetPaymentMethod({
            config: {
                paymentSecret: paymentSecret,
                methodName: method,
            },
            callbacks: {
                success,
                error,
            }
        })
    }

    const GetPaymentHttp = useHttp<DynamicObject>(configGetPaymentApi)
    const requestGetPayment = GetPaymentHttp.request
    GetPaymentHttp.request = () => {
        const success = (response: AxiosResponse) => {
            setCart(response.data.cart)
        }

        const error = (response: AxiosError) => {
            navigate('/page-not-found')
        }

        requestGetPayment({
            config: {
                paymentSecret: paymentSecret,
            },
            callbacks: {
                success,
                error,
            }
        })
    }

    useEffect(()=>{
        GetPaymentMethodHttp.request()
        GetPaymentHttp.request()
    },[paymentSecret])

    useEffect(() => {
        if (!data)
            return

        axios.post(
            data.payments.available_methods.url,
            {...data.payments.available_methods.postData, unified: true},
            {
                headers: {
                    country: data.payments.country_code??"jo",
                    source: 'desktop',
                    "enforce-language": i18n.language
                }
            }
        ).then(response => {
            if (data.payments.available_methods.viewType['type'] == FRAME || data.payments.available_methods.viewType['type'] ==  FRAME_WITHOUT_HEADERS) {

                setFetchHtml(response.data)

            } else if (data.payments.available_methods.viewType['type'] == REDIRECT) {

                window.location.href = response.data.redirectTo
            } else if (data.payments.available_methods.viewType['type'] == INTERNAL_FRAME) {

                navigate(`${process.env.REACT_APP_PAYMENT_DIR}/internalFrame`,{
                    state: {
                        data: response.data,
                        cartId:data.payments.source_transaction_id

                    }
                })
            }
        })

    }, [data])
    const {t} = useTranslation();
    const urlParams = new URLSearchParams(window.location.search)
    let isWebView: boolean = (String(urlParams.get('webView')).toLowerCase() === 'true') || false

    return (
        fetchHtml &&
        <div>
            {!isWebView &&
            <Navbar title={data.payments.available_methods.label} subTitle={mustache(t('page.payment.subtitle'), {
                'cartId': data.payments.source_transaction_id
            })} loading={false}/>}
            {
                <Container className={`sm-pad-zero`}>
                    <Row className={`justify-content-center`}>
                        <Col lg={8} md={10} sm={12}>
                            <div>
                                {!isWebView && cart &&
                                <CartSummary shortCartSummary={true} cart={cart} summary={cart.summary}   />
                                    }
                                <IframeContainer width={'100%'} height={"720px"}
                                                 docString={fetchHtml.html}/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            }
        </div>
    )
}

export default PaymentMethod