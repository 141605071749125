import {AxiosError, AxiosResponse} from "axios";

interface Result {
    success: (response: AxiosResponse) => AxiosResponse,
    error: (error: AxiosError) => AxiosError|Promise<AxiosError>,
}

export default (): Result => {
    return {
        success: (response: AxiosResponse) => {
            return response
        },
        error: (err: AxiosError) => {
            console.log('error from interceptor')
            console.log(err.response.data)
            return Promise.reject(err);
        }
    }
}

