import style from "./ZeroCreditPopup.module.css"
import {useEffect, useRef, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {IoIosCloseCircle} from "react-icons/io";
import {useTranslation} from "react-i18next";
import {RiErrorWarningFill} from "react-icons/ri";
import {ZeroCreditPopupProps} from "../Interface";

const ZeroCreditPopup = (props: ZeroCreditPopupProps) => {

    const {t} = useTranslation()

    const closePopup = () => {
        const elem = document.getElementById('wrapper');
        const bg = document.getElementById('background-popup');
        bg.style.display = "none";
        elem.style.transition = "all 0.3s ease-in-out";
        elem.style.bottom = `-${elem.offsetHeight.toString()}px`;
    }

    const showPopup = () => {
        const elem = document.getElementById('wrapper');
        const bg = document.getElementById('background-popup');
        bg.style.display = "block";
        elem.style.transition = "all 0.3s ease-in-out";
        elem.style.bottom = "0px";
    }

    const handleClickOutside = () => {
        props.invokeToggle()
    }

    useEffect(() => {
        const bg = document.getElementById('background-popup');
        if (props.invokePopup) {
            bg.addEventListener("mousedown", handleClickOutside)
            showPopup()
        } else {
            bg.removeEventListener("mousedown", handleClickOutside)
            closePopup()
        }
    }, [props.invokePopup])

    return (
        <Container className={`${style.wrapper}`} id={'wrapper'}>
            <Row className={`justify-content-center`}>
                <Col lg={8} md={10} sm={12}>
                    <div className={`${style.color_white}`}>
                        <div className={`${style.max_pad}`}>
                            <Row>
                                <Col>
                                    <div className={`${style.title_text}`}>{t('wallet.pay')}</div>
                                </Col>
                                <Col sm={4} lg={4} md={4} xs={4}>
                                    <IoIosCloseCircle onClick={props.invokeToggle}
                                                      className={`float-start ${style.close_btn}`}></IoIosCloseCircle>
                                </Col>
                            </Row>
                            <Row className={`mt-5`}>
                                <Col className={`${style.padding0}`}>
                                    <span><RiErrorWarningFill style={{width:"50px",height:"30px" ,color:"red"}}/></span>
                                    <span style={{fontWeight: "bold", fontSize: "18px",color:"red",margin:"0"}}>{props.title}</span>

                                </Col>

                            </Row>

                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default ZeroCreditPopup