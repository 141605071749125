import {AxiosRequestConfig} from "axios";
import i18n from "i18next";


export default async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {

    const lang = i18n.language
    let countryCode = localStorage.getItem('countryCode')
    if (countryCode == null || countryCode==undefined)
        countryCode = 'jo'

    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    config.headers['source'] = 'desktop'
    config.headers['country'] = countryCode
    config.headers['enforce-language'] = lang

    config.params = {
        ...config.params,
    }

    return config
}