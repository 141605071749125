import { useState} from "react";
import {Button} from "@mui/material";
import {Col, Row} from "react-bootstrap";
import style from './CartItem.module.css';
import {CartItemProps} from "../Interface";
import {AxiosError, AxiosResponse} from "axios";
import {useNavigate, useParams} from "react-router";
import trash from '../../../Assets/Images/trash.png';
import {DynamicObject} from "../../../models/General";
import useHttp from "../../../Hooks/use-http/use-http";
import {configDeleteCartItemApi} from "../../../Api/api.config";
import i18n from "../../../i18n";


const CartItem = (props: CartItemProps) => {

    let {memberId, cartId, identification} = useParams();
    let navigate = useNavigate()

    /*-----------------------------
     *           STATES
     * ----------------------------*/
    const [holdDeletion, setHoldDeletion] = useState<boolean>(false)


    /*-----------------------------
     *           REQUEST
     * ----------------------------*/
    const deleteCartItemHttp = useHttp<DynamicObject>(configDeleteCartItemApi)

    const deleteCartItem = (id: string) => {
        setHoldDeletion(true)

        const requestDeleteCartItem = deleteCartItemHttp.request


        const success = (response: AxiosResponse) => {
            if (response.data.success) {
                setHoldDeletion(false)
                props.deleteFromList(response.data.deletedId)
                props.reloadCart(response.data.cart)
            }
        }
        const error = (error: AxiosError) => {
            navigate(process.env.REACT_APP_404_REDIRECT)
        }

        requestDeleteCartItem({
            config: {
                memberId: memberId,
                cartId: cartId,
                identification: identification,
                id: id
            },
            callbacks: {
                success,
                error,
            }
        })
    }

    return (
        <div dir={i18n.language=='en'?'ltr':'rtl'}  className={`${style.item_container} ${props.borderFix ? style.border : ''}`}>
            <div className={`${style.item_wrapper}`}>
                <Row className={`${style.item_row} mb-2`}>
                    <Col sm={2} lg={2} xl={2} md={2} xs={2} className={`${style.item_col}`}>
                        <img src={props.item.icon} className={style.item_img} alt={''}/>
                    </Col>
                    <Col className={`${style.item_margin}`}>
                        <span className={`${style.size_text}`}>
                            <b>{props.item.serviceLabel}</b>
                        </span>
                        <div className={`${i18n.language=='en'?'float-end':'float-start'}`}>
                            <span
                                className={style.size_text}><b>{props.item.countryPrice}</b> {props.item.countryCurrency}</span>
                        </div>
                    </Col>
                </Row>
                <Row className={`${style.item_row} mb-2`}>
                    <Col sm={2} lg={2} xl={2} md={2} xs={2} className={`${style.item_col}`}>

                    </Col>
                    <Col className={`${style.item_margin}`}>
                        <span className={`${style.size_text}`}>
                            {props.item.label}
                        </span>
                    </Col>
                </Row>
                <Row className={style.item_row}>
                    <Col>
                        {
                            // (!props.hideDeletion && !props.isPaymentLink) &&
                            // <Col className={`${style.size_icon} float-start d-inline-block`}>
                            //     <Button className={`${style.trash_btn} p-0`} variant={"text"} onClick={() => {
                            //         deleteCartItem(props.item.id)
                            //     }} disabled={holdDeletion}>
                            //         <img src={trash} className={style.size_trash_icon}/>
                            //     </Button>
                            // </Col>
                        }
                    </Col>

                </Row>
            </div>
            {props.clearFix ? <hr className={style.item_seperator}/> : ''}
        </div>
    )
}

export default CartItem