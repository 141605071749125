import {PaymentItem as PaymentItemModel} from "../../models";
import style from './Payment.module.css';
import {useEffect, useState} from "react";
import PaymentItem from "./PaymentItem";
import Summary from "../../models/summary";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import i18n from "../../i18n";
import {useTranslation} from "react-i18next";
import Cart from "../../models/cart";
import Payment from "../../models/payment";

export interface Props {
    items: PaymentItemModel[];
    cartId: number;
    cartSummary: Summary;
    loading: boolean,

    payment_secret?:string,
}

const PaymentItemsList = (props: Props) => {
    const {t} = useTranslation()

    const [list, setList] = useState<PaymentItemModel[]>(props.items)

    useEffect(() => {
        if (props.items.length > 0)
            setList(props.items)
    }, [props.items])

    return (
        props.loading ? <Skeleton style={{marginTop:"10px"}} count={5} width={"100%"} height={"55px"}/> : <div  dir={i18n.language=='en'?'ltr':'rtl'}  className={style.list_container}>
            <h3 className={`md_text fw-bold m-2`}>{t('page.payment.title')}</h3>
            {list.map((item, index, allItems) => {
                return (
                    <div  dir={i18n.language=='en'?'ltr':'rtl'} >
               <PaymentItem payment_secret={props.payment_secret} cartSummary={props.cartSummary} cartId={props.cartId}
                                     clearFix={allItems.length - 1 === index} item={item}/>
                    </div>
                )
            })}
        </div>
    )

}

export default PaymentItemsList