import Cart from "../models/cart";
import {useNavigate, useParams} from "react-router";
import Skeleton from 'react-loading-skeleton'
import {useTranslation} from "react-i18next";
import {mustache} from "../Helpers/functions";
import {DynamicObject} from "../models/General";
import Navbar from "../components/Navbar/Navbar";
import {emptyCart, emptyPayment} from "../Constants/defaultData";
import 'react-loading-skeleton/dist/skeleton.css'
import useHttp from "../Hooks/use-http/use-http";
import {configGetCartApi} from "../Api/api.config";
import {Col, Container, Row} from "react-bootstrap";
import axios, {AxiosError, AxiosResponse} from "axios";
import CartSummary from "../components/Cart/CartSummary/CartSummary";
import React, {useCallback, useEffect, useState} from "react";
import CartItemsList from "../components/Cart/CartItemsList/CartItemsList";
import CouponForm from "../components/Coupon/CouponForm/CouponForm";
import PurchaseButton from "../components/PurchaseButton/PurchaseButton";
import PaymentItemsList from "../components/PaymentItems/PaymentItemsList";
import Payment from "../models/payment";
import style from "../components/PaymentItems/Payment.module.css";
import PaymentFooter from "../components/PaymentItems/PaymentFooter";


const CartView = () => {

    const [loading, setLoading] = useState<boolean>(false)
    let {memberId, cartId, identification} = useParams();
    const {t} = useTranslation();
    const [payment, setPayment] = useState<Payment>(emptyPayment)
    const [cart, setCart] = useState<Cart>(emptyCart)
    const navigate=useNavigate()





    /*-----------------------------
    *           REQUEST
    * ----------------------------*/
    const getCartHttp = useHttp<DynamicObject>(configGetCartApi)
    const requestGetCart = getCartHttp.request

    getCartHttp.request = useCallback((args) => {
        const success = (response: AxiosResponse) => {
            localStorage.setItem('countryCode', response.data.cart.summary.countryCode)
            setPayment(response.data.payments)
            setCart(response.data.cart)
            setLoading(false)
        }
        const error = (response: AxiosError) => {
            setLoading(false)
            navigate(process.env.REACT_APP_404_REDIRECT)
        }

        requestGetCart({
            config: {
                memberId: memberId,
                cartId: cartId,
                identification: identification,

            },
            callbacks: {
                success,
                error,
            }
        })
    }, [cartId, identification, memberId])

    /*-----------------------------
    *           FUNCTIONS
    * ----------------------------*/
    const reloadCart = (cart: Cart) => {
        setCart(cart)
    }

    const reloadPayment = (payment: Payment) => {
        setPayment(payment)
    }
    /*-----------------------------
    *           EFFECTS
    * ----------------------------*/
    useEffect(() => {

        setLoading(true)
        getCartHttp.request()
    }, [cartId, identification, memberId])


    return (
        <div>
            <div className={`${style.blocked_background}`} id={'background-popup'}>
            </div>
                   <Container className={`sm-pad-zero `}>
                          <Row className={`justify-content-center `}>
                              <Col className={`${style.parent_container}`} lg={6} md={8} sm={10}>
                                  <Navbar referral_url={cart.referral_url} loading={loading} title={t('page.payment.title')}
                                          subTitle={mustache(t('page.payment.subtitle'), {cartId: cart.cartId})}/>
                                  <div style={{marginTop:"30px"}}>
                                      <CartItemsList loading={loading} reloadCart={reloadCart} items={cart.cartItems}/>
                                      <CartSummary reloadPayment={reloadPayment} reloadCart={reloadCart} shortCartSummary={false} loading={loading} summary={cart.summary} cart={cart}/>


                                  </div>
                              </Col>
                          </Row>

                   </Container>
            <Container className={`sm-pad-zero`}>
                <Row className={`justify-content-center`}>
                    <Col lg={6} md={8} sm={10}>
                        <PaymentItemsList  payment_secret={payment.payment_secret}  loading={loading} cartSummary={cart.summary} cartId={cart.cartId}
                                          items={payment.available_methods}/>
                        <PaymentFooter loading={loading} lines={payment.footer}/>

                    </Col>
                </Row>
            </Container>

        </div>
    )
}
export default CartView