import {ChangeEvent, useEffect, useState} from "react";
import i18n from "../../../i18n";
import {Col} from "react-bootstrap";
import {InputAdornment, TextField} from "@mui/material";
import style from './CouponForm.module.css';
import Modal from "../../../UI/Modal/Modal";
import Skeleton from 'react-loading-skeleton'
import {CouponFormProps} from "../Interface";
import {useTranslation} from "react-i18next";
import {AxiosError, AxiosResponse} from "axios";
import 'react-loading-skeleton/dist/skeleton.css'
import {useNavigate, useParams} from "react-router";
import {DynamicObject} from "../../../models/General";
import useHttp from "../../../Hooks/use-http/use-http";
import ApplyRemoveCouponButton from "../ApplyRemoveCouponButton/ApplyRemoveCouponButton";
import {configDeleteCouponCodeApi, configUseCouponCodeApi} from "../../../Api/api.config";
import RemoveCouponConfirmation from "../RemoveCouponConfirmation/RemoveCouponConfirmation";
import {check422Error} from "../../../Helpers/functions";


const CouponForm = (props: CouponFormProps) => {


    let navigate = useNavigate()
    let {memberId, cartId, identification} = useParams();

    const {t} = useTranslation()

    const [couponUsed, setCouponIsUsed] = useState<boolean>(false)
    const [couponCode, setCouponCode] = useState<string>(props.cart.summary.coupon.code ?? '')
    const [error, setError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')


    const [isOpen, setIsOpen] = useState<boolean>(false)


    useEffect(() => {
        setCouponIsUsed(!!Object.values(props.cart.summary.coupon).length)
        setCouponCode(props.cart.summary?.coupon?.code?.length > 0 ? props.cart.summary.coupon.code : '')

    }, [props.cart.summary.coupon])


    const keyStrokeCoupon = (e: ChangeEvent<any>) => {
        setCouponCode(e.target.value)
        hideError()
    }

    const hideError = () => {
        setError(false)
        setErrorMessage('')
    }

    const displayError = (message: string) => {
        setError(true)
        setErrorMessage(message)
    }

    /*-----------------------------
     *    USE COUPON REQUEST
     * ----------------------------*/
    const postUseCouponCodeHttp = useHttp<DynamicObject>(configUseCouponCodeApi)
    const requestPostUseCouponCode = postUseCouponCodeHttp.request

    /*-----------------------------
     *   DELETE COUPON REQUEST
     * ----------------------------*/
    const postDeleteCouponCodeHttp = useHttp<DynamicObject>(configDeleteCouponCodeApi)
    const requestPostDeleteCouponCode = postDeleteCouponCodeHttp.request


    /*-----------------------------
     *       CLICK HANDLER
     * ----------------------------*/
    const useCouponCode = () => {
        if (!couponUsed) {
            const success = (response: AxiosResponse) => {
                hideError()
                setCouponIsUsed(!!Object.values(response.data.cart.summary.coupon).length)
                props.reloadPayment(response.data.payments)
                props.reloadCart(response.data.cart)
            }
            const error = (error: AxiosError) => {
                if (check422Error(error)) {
                    displayError(error.response?.data['error'])

                } else {
                    navigate(process.env.REACT_APP_404_REDIRECT)
                }
            }

            requestPostUseCouponCode({
                config: {
                    memberId: memberId,
                    cartId: cartId,
                    identification: identification,
                    data: {
                        CouponForm: {
                            coupon_code: couponCode
                        }
                    }
                },
                callbacks: {
                    success,
                    error,
                },
            },)
        } else {
            setIsOpen(true)
            if (isOpen) {
                const success = (response: AxiosResponse) => {
                    setCouponIsUsed(!!Object.values(response.data.cart.summary.coupon).length)
                    props.reloadPayment(response.data.payments)
                    props.reloadCart(response.data.cart)
                    setIsOpen(false)
                }
                const error = (err: AxiosError) => {

                    if (check422Error(err)) {
                        displayError(err.response?.data['error'])
                    } else {
                        navigate(process.env.REACT_APP_404_REDIRECT)

                    }
                }

                requestPostDeleteCouponCode({
                    config: {
                        memberId: memberId,
                        cartId: cartId,
                        identification: identification,
                        data: {
                            CouponForm: {
                                coupon_code: couponCode
                            }
                        }
                    },
                    callbacks: {
                        success,
                        error,
                    },
                },)
            }
        }

    }

    // useEffect(() => {
    //     const coupon = props.cart.summary.coupon.code ?? ''
    //     if (coupon.length > 0)
    //         setCouponCode(coupon)
    // }, [props.cart.summary.coupon])

    return (
        <div dir={i18n.language == 'en' ? 'ltr' : 'rtl'} className={style.container}>
            <div className={`${style.wrapper}`}>
                <div className={style.width}>
                    <Col sm={12} lg={12} md={12} xs={12} className={style.input_col}>
                        <TextField
                            dir={i18n.language == 'en' ? 'ltr' : 'rtl'}
                            className={`${style.border_radius}`}
                            error={error}
                            helperText={errorMessage}
                            id={'coupon'}
                            value={couponCode}
                            placeholder={`${t('coupon.label')}`}
                            onChange={keyStrokeCoupon}
                            fullWidth
                            InputProps={
                                {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                                <span onClick={useCouponCode}
                                                      className={`${style.coupon_apply}`}>{couponUsed ? t('coupon.clear') : t('coupon.apply')}</span>
                                        </InputAdornment>
                                    )
                                }}
                        ></TextField>
                        {isOpen && couponUsed && <Modal title={''} setIsOpen={setIsOpen}>
                            <RemoveCouponConfirmation useCouponCode={useCouponCode}
                                                      setIsOpen={setIsOpen}></RemoveCouponConfirmation>
                        </Modal>
                        }
                    </Col>
                </div>

            </div>
        </div>
    )
}

export default CouponForm