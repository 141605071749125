import Cart from "../models/cart";
import Payment from "../models/payment";

export const emptyCart: Cart = {
    memberId: 0,
    cartId: 0,
    status: 0,
    cartItems: [],
    isPaymentLink: false,
    summary: {
        total: "0.00",
        subTotal: "0.00",
        tax: "0.00",
        taxPercentage: "0",
        discount: "0.00",
        discountPercentage: "0",
        currency: "",
        coupon: {},
        cartId: 0
    },
}
export const emptyPayment: Payment = {
    id: '',
    source_transaction_id: '',
    payment_secret: '',
    available_methods: [],
    footer: []
}