import {useEffect, useState} from "react";
import CartItem from "../CartItem/CartItem";
import Skeleton from 'react-loading-skeleton'
import style from './CartItemsList.module.css';
import {CartItemsListProps} from "../Interface";
import 'react-loading-skeleton/dist/skeleton.css'
import {CartItem as CartItemModel} from "../../../models";
import i18n from "../../../i18n";


const CartItemsList = (props: CartItemsListProps) => {

    const [list, setList] = useState<CartItemModel[]>(props.items)

    const handleRemove = (id: string) => {
        const filteredList = list.filter((item) => item.id !== id)
        setList(filteredList)
    }

    useEffect(() => {
        if (props.items.length > 0)
            setList(props.items)
    }, [props.items])


    return (
        props.loading ? <Skeleton width={"100%"} height={"100px"} style={{ marginBottom: "5px"}}/> :
            <div dir={i18n.language=='en'?'ltr':'rtl'}className={style.list_container}>
                {list.map((item, index, allItems) => {
                    return (<CartItem reloadCart={props.reloadCart} deleteFromList={handleRemove}
                                      hideDeletion={allItems.length === 1} item={item}
                                      borderFix={[allItems.length - 1, 0].includes(index)}
                                      clearFix={allItems.length - 1 === index}
                                      isPaymentLink={props.isPaymentLink}
                        />

                    )
                })}
            </div>)

}

export default CartItemsList