import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {IoArrowBack, IoArrowForward} from "react-icons/io5";
import style from './Status.module.css';
import {AiOutlineCheckCircle, AiOutlineCloseCircle} from "react-icons/ai";
import PaymentInfoItem from "../Cart/PaymentInfoItem/PaymentInfoItem";
import React from "react";
import {FaRedoAlt, FaUndoAlt} from "react-icons/fa";
import {StatusProps} from "./Interface";
import i18n from "../../i18n";
import {getLang, getUrlBasedOnCountry} from "../../Helpers/functions";

const Status = (props: StatusProps) => {
    const {t} = useTranslation();
    let {status, paymentSecret, cartId} = useParams();

    const onClickSuccessHandler = () => {
        window.location.href = props.data.referral_url;
    }

    return (
        <div dir={i18n.language=='en'?'ltr':'rtl'} className={style.container}>
            <div className={style.row}>
                {props.success ? <AiOutlineCheckCircle
                        className={`${style.color_success} ${style.huge_icon}`}></AiOutlineCheckCircle>
                    : <AiOutlineCloseCircle className={`${style.color_fail} ${style.huge_icon}`}></AiOutlineCloseCircle>
                }
            </div>
            <div className={`${style.row} mb-1`}>
                <h1 className={`${style.no_pad} ${style.bold} ${style.font_size_md}`}>{props.success ? t('payment.success') : t('payment.failed')}</h1>
            </div>
            {!props.success && <div className={style.row}>
                <p className={`${style.text_align_center} ${style.font_size_sm} mb-0`}>{props?.data?.gatewayStatusMessage ?? ''}</p>

            </div>}
            <div className={`${style.payment_info_container_style} mt-3 mb-2 `}>
                <PaymentInfoItem
                    label={t('order.no')}
                    value={cartId}
                    type={'bold'}
                    cardId={true}
                    extra='copy'
                    isTotal={false}

                />
                {/*<hr style={{margin: "5px 0px"}}/>*/}
                {/*<PaymentInfoItem*/}
                {/*    label={(t('total.total'))}*/}
                {/*    value={valueWithCurrency ?? ''}*/}
                {/*    type={'gray'}*/}
                {/*    cardId={false}*/}
                {/*    extra=""*/}
                {/*/>*/}
            </div>
            <div className={`row ${style.button_container}`}>
                <button className={`${style.btn}`} onClick={onClickSuccessHandler}>
                    <div className={`row`} >
                        <div className={`col-12`}>
                            <div
                                className={`${style.buttonTitle}`}> {props.success ? t('returnBack') : t('pleaseTryAgain')}
                            </div>
                            <span className={`${i18n.language=='en'?`float-end`:'float-start'} `}><div
                                className={`${style.iconContainer}`}> {props.success ? i18n.language=='en'?<IoArrowForward/>: <IoArrowBack/> :
                                i18n.language=='en'?<FaRedoAlt/>:<FaUndoAlt/>}</div></span>
                        </div>

                    </div>

                </button>
            </div>

        </div>
    )
}

export default Status