import {redirect, useLocation, useNavigate, useParams} from "react-router";
import React, {useEffect, useState} from "react";
import Navbar from "../components/Navbar/Navbar";
import {useTranslation} from "react-i18next";
import {mustache} from "../Helpers/functions";
import axios from "axios";
import i18n from "../i18n";
import CartItemsList from "../components/Cart/CartItemsList/CartItemsList";
import Cart from "../models/cart";
import CouponForm from "../components/Coupon/CouponForm/CouponForm";
import CartSummary from "../components/Cart/CartSummary/CartSummary";
import PurchaseButton from "../components/PurchaseButton/PurchaseButton";
import Load from "../components/Load/Load";
import {Col, Container, Row} from "react-bootstrap";
import IframeContainer from 'iframe-script-container';

const InternalFrame = () => {
    const location = useLocation();
    const data = location.state
    const url = data.data.redirectTo
    const {t} = useTranslation();


    return (
        <div>
            {
                <Navbar title={location.state.label} subTitle={mustache(t('page.payment.subtitle'), {
                    'cartId': data.cartId
                })} loading={false}/>}

            <Container className={`sm-pad-zero`} style={{height: "100%"}}>
                <Row className={`justify-content-center`} style={{height: "100%"}}>
                    <Col lg={8} md={10} sm={12}>
                        <iframe src={url} width={'100%'} height={'100%'}/>
                    </Col>
                </Row>
            </Container>

        </div>

    )
}

export default InternalFrame