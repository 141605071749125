import GetCart from "../Api/Requests/get-cart.request";
import GetPayment from "../Api/Requests/get-payment.request";
import DeleteCartItemApi from "./Requests/delete-cart-item.request";
import PostUseCouponCode from "./Requests/post-use-coupon-code.request";
import PostCreatePayment from "./Requests/post-create-payment.request";
import GetPaymentStatus from "../Api/Requests/get-payment-status.request";
import PostDeleteCouponCode from "./Requests/post-delete-coupon-code.request";
import PostPaymentFailedStatusRequest from "./Requests/post-payment-failed-status.request";
import PostPushPayment from "./Requests/post-push-Paymnet.request";
import PostUseCredit from "./Requests/post-use-credit.request";
import GetPaymentMethodRequest from "./Requests/get-payment-method.request";


export const configGetCartApi = {
    callback: GetCart,
    initialData: {},
    withLoader: false
}
export const configGetPaymentMethodApi = {
    callback: GetPaymentMethodRequest,
    initialData: {},
    withLoader: false
}
export const configGetPaymentApi = {
    callback: GetPayment,
    initialData: {},
    withLoader: false
}
export const configGetPaymentStatusApi = {
    callback: GetPaymentStatus,
    initialData: {},
    withLoader: false
}
export const configGetPaymentFailedStatusApi = {
    callback: PostPaymentFailedStatusRequest,
    initialData: {},
    withLoader: false
}
export const configDeleteCartItemApi = {
    callback: DeleteCartItemApi,
    initialData: {},
    withLoader: false
}
export const configUseCouponCodeApi = {
    callback: PostUseCouponCode,
    initialData: {},
    withLoader: false,
}
export const configDeleteCouponCodeApi ={
    callback: PostDeleteCouponCode,
    initialData: {},
    withLoader: false,
}
export const configCreatePaymentApi ={
    callback: PostCreatePayment,
    initialData: {},
    withLoader: false,
}
export const configPushPaymentApi ={
    callback: PostPushPayment,
    initialData: {},
    withLoader: false,
}
export const configUseCreditApi ={
    callback: PostUseCredit,
    initialData: {},
    withLoader: false,
}


