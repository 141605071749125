import {AxiosError} from "axios";

export const mustache = (string: string, data: Object) => {
    return Object.entries(data).reduce((res, [key, value]) => {
        const search = `{{${key}}}`

        if (
            res.indexOf(search) !== -1 &&
            res.indexOf(`\\${key}`) == -1 &&
            res.indexOf(`${key}\\`) == -1
        ) {
            return res.replace(search, value)
        }

        return res
    }, string)
}

export const check422Error = (error: AxiosError) => {

    if (error.response.data['statusCode'] == 422) {
        return true

    } else {
        return false
    }

}

export const getLang = (): string => {
    const urlParams = new URLSearchParams(window.location.search)
    const langParam = urlParams.get('lang')
    const langLocalStorage = localStorage.getItem('lang')
    let lang = 'ar'
    if (langParam) {
        lang = langParam
        localStorage.setItem('lang', langParam)
    } else if (langLocalStorage) {
        lang = langLocalStorage
    }
    return lang
}
export function getUrlBasedOnCountry(country: string,lang:string): string {
    let url = process.env.REACT_APP_BACK_BUTTON_REDIRECT as string
    url = url.replace("XX", country)
    url = url.replace("LANG", lang)
    return url;
}
function isValidHttpUrl(s) {
    let url;
    try {
        url = new URL(s);
    } catch (e) { return false; }
    return /https?/.test(url.protocol);
}