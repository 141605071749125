import Base from "./base";
import {BaseConfig} from "../interfaces";
import DefaultPaymentRequestInterceptor from "../Interceptors/default-payment-request.interceptor";
import DefaultPaymentResponseInterceptor from "../Interceptors/default-payment-response.interceptor";

export default class PaymentApi extends Base{

    protected configuration () : BaseConfig {
        return {
            baseURL: process.env.REACT_APP_PAYMENT_API_URL,
            timeOut: (process.env.REACT_APP_API_TIMEOUT || 5000) as number
        }
    }

    /*--------------------------------
         DEFINE ABSTRACT FUNCTIONS
    ---------------------------------*/

    defaultRequestInterceptors(): any[] {
        return [
            DefaultPaymentRequestInterceptor
        ];
    }

    defaultResponseInterceptors(): any[] {
        return [
            DefaultPaymentResponseInterceptor
        ];
    }
}