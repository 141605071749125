import axios, {AxiosInstance} from "axios";
import env from "ts-react-dotenv";
import {BaseConfig} from "../interfaces";


    /*-------------------------
            SINGLETON CLASS
    --------------------------*/

export default abstract class Base {

    private static obj: any
    public request?: AxiosInstance
    protected config?: BaseConfig

    protected constructor() {
    }

    /*-------------------------
        ABSTRACT FUNCTIONS
    --------------------------*/
    abstract defaultRequestInterceptors(): any[]

    abstract defaultResponseInterceptors(): any[]

    /*-------------------------
          DEFAULT CONFIG
    --------------------------*/
    protected configuration(): BaseConfig {
        return {
            baseURL: "http://localhost",
            timeOut: env.API_TIMEOUT || 5000
        }
    }

    protected init() {
        this.config = this.configuration()

        this.request = axios.create({
            baseURL: this.config?.baseURL
        })

        const requestInterceptors = this.defaultRequestInterceptors()
        const responseInterceptors = this.defaultResponseInterceptors()

        /*LOOP TO GET ALL INTERCEPTOR */
        for (const requestInterceptor of requestInterceptors)
            this.request?.interceptors.request.use(requestInterceptor)

        for (const responseInterceptor of responseInterceptors) {
            const interceptor = responseInterceptor()
            this.request?.interceptors.response.use(interceptor.success, interceptor.error)
        }
    }

    static getInstance() {

        if (!this.obj) {
            this.obj = new (<any>this)();
        }

        this.obj.init()
        return this.obj
    }
}