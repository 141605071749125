import {useState} from "react";
import {Col, Row} from "react-bootstrap";
import {FaRedoAlt} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import {BsClipboardCheck} from "react-icons/bs";
import style from "./PaymentInfoItem.module.css";
import {PaymentInfoItemProps} from "../Interface";
import i18n from "../../../i18n";

const PaymentInfoItem = (props: PaymentInfoItemProps) => {
    const {t} = useTranslation();

    const dir = props.dir ?? "ltr";


    const [clickedObject, setClickedObject] = useState({
        isClicked: false,
        firstTime: true
    })

    const copyHandler = (e) => {
        setClickedObject({
            isClicked: true,
            firstTime: false
        })
        navigator.clipboard.writeText(props.value);
        setTimeout(() => {
            setClickedObject({
                isClicked: false,
                firstTime: false
            })
        }, 3000)
    }

    return (
        <Row dir={i18n.language=='en'?'ltr':'rtl'}>
            <Col
                className={`${props.isTotal ? style.total_font_size : style.normal_font_size} ${props.type === 'bold' ? `${style.centered_text} ${style.bold_text}` : props.type === '' ? `${style.centered_text}` : `${style.centered_text} ${style.gray_text}`}`}
            >{props.label}</Col>
            <Col style={{display:"flex",justifyContent:"flex-end"}}>
                <div dir={i18n.language=='en'?'ltr':'ltr'}
                    className={`${props.isTotal ? style.total_font_size : style.normal_font_size} ${props.cardId ? `${style.cardId_container}` : ''} `}
                >

                        <span
                            className={`${props.type === 'bold' ? `${style.black_text} ${style.bold}` : props.type === '' ? `` : style.gray_text}`}
                            dir={dir}>{props.value}</span>
                   {props.extra == 'copy' ? <>
                       {clickedObject.isClicked && !clickedObject.firstTime ? <span onClick={copyHandler}
                                                                                    className={`${style.copy_style} ${style.cursor}`}><BsClipboardCheck
                           style={{
                               color: "#00bf99"
                           }}/> </span> : clickedObject.firstTime ? <span onClick={copyHandler}
                                                                                className={`${style.copy_style} ${style.padding_0} ${style.cursor}`}>{t(props.extra)} </span> :
                           <span style={{
                               color: "#00bf99"
                           }} className={`${style.copy_style} ${style.cursor}`}
                                 onClick={copyHandler}><FaRedoAlt></FaRedoAlt></span>}
                   </> : <span> {props.extra} </span>
                   }

                </div>
            </Col>
        </Row>
    )
}

export default PaymentInfoItem