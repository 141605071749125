import style from "./CartSummary.module.css"
import {useTranslation} from "react-i18next";
import Skeleton from 'react-loading-skeleton'
import {CartSummaryProps} from "../Interface";
import PaymentInfoItem from "../PaymentInfoItem/PaymentInfoItem";
import 'react-loading-skeleton/dist/skeleton.css'
import {mustache} from "../../../Helpers/functions";
import i18n from "../../../i18n";
import CouponForm from "../../Coupon/CouponForm/CouponForm";
import React from "react";

const CartSummary = (props: CartSummaryProps) => {

    const {t} = useTranslation()
    const hasDiscount = !!Math.ceil(Number(props.summary.discount))
    const hasTax = !!Math.ceil(Number(props.summary.tax))
    const discountPer = props.cart.isPaymentLink?props.summary.discountPercentage:props.summary.coupon.discountPercentage
    console.log(props.summary)
    return (
        props.shortCartSummary ? <div className={`${style.shortSummery_container}`}>
            <div dir={i18n.language=='en'?'ltr':'rtl'} className={`${style.shortSummery_row}`}>
                <div className={`${style.shortSummery_col}`}>
                    <div className={`${style.shortSummery_font_header}`}>{t('payment.cart.total')}</div>
                </div>
                <div className={`${style.shortSummery_col}`}>
                    <div
                        className={`${style.shortSummery_font_header_lg}`}>{props.summary.total} {props.summary.currency}</div>
                </div>
            </div>
        </div> : <div dir={i18n.language=='en'?'ltr':'rtl'} className={style.container}>
            {props.loading ? <Skeleton width={"100%"} height={"150px"} style={{marginBottom:"5px"}}/> :
                <div className={style.wrapper}>
                    <div className={`${style.title}`}>
                        {t('total.title')}
                    </div>
                    {!props.cart.isPaymentLink && <CouponForm reloadPayment={props.reloadPayment} cart={props.cart}
                                                        reloadCart={props.reloadCart}/>}

                    <div className={`${style.text_box}`}>

                        {
                            <PaymentInfoItem
                                label={t('total.subtotal')}
                                value={props.summary.subTotal}
                                type={''}
                                extra={props.summary.currency}
                                isTotal={false}
                            />
                        }

                        { discountPer > 0 && <PaymentInfoItem
                            label={mustache(t('total.discount'), {percent: discountPer??0})}
                            value={props.summary.discount}
                            type={''}
                            extra={props.summary.currency}
                            isTotal={false}

                        />}

                        {
                            (["0"]).includes(props.summary.taxPercentage) || hasTax &&
                            <PaymentInfoItem
                                label={mustache(t('total.tax'), {percent: props.summary.taxPercentage})}
                                value={props.summary.tax}
                                type={''}
                                extra={props.summary.currency}
                                isTotal={false}

                            />
                        }

                    </div>
                    {props.summary.total && <hr className={`${style.separator}`}/>}
                    <div className={style.total_text_box}>
                        <div>
                            <PaymentInfoItem
                                label={t('total.total')}
                                value={props.summary.total}
                                type={'bold'}
                                extra={props.summary.currency}
                                isTotal={true}

                            />
                        </div>
                    </div>
                </div>}

        </div>

    )
}

export default CartSummary