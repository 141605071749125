import {Oval} from "react-loading-icons";
import {Container} from "react-bootstrap";

export interface Props {
    color?: string
}

const Load = (props: Props) => {
    return (
        <Container className={'h-inherit d-flex align-items-center justify-content-center'}>
            <Oval strokeWidth={4} stroke={`${props.color ?? "#0179FF"}`}/>
        </Container>
    )
}

export default Load