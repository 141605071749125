import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {RemoveCouponConfirmationProps} from "../Interface";
import style from "./RemoveCouponConfirmation.module.css";
import i18n from "i18next";


const RemoveCouponConfirmation = (props:RemoveCouponConfirmationProps) => {
     const {t}=useTranslation()
    return (
        <div dir={i18n.language=='en'?"ltr":"rtl"}>
            <p style={{fontSize: "20px", fontWeight: "bold"}}>{t('clearCouponConfirmation')}</p>
            <div className={`${style.modal_container}`}>
                <Button className={`${style.confirmationButton}`} variant="contained" fullWidth={true}
                        onClick={props.useCouponCode}>{t('yes')}</Button>
                <Button className={`${style.confirmationButton}`} variant="contained" fullWidth={true} onClick={() => {
                   props.setIsOpen(false)
                }}>{t('no')}</Button>
            </div>
        </div>
    )
}
export  default RemoveCouponConfirmation